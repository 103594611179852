<template>
	<div class="bluetitle">
		<div class="blue"></div>
		<div class="title">
			<slot></slot>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PcWebsiteBlueTitle',

	data() {
		return {

		};
	},

	mounted() {

	},

	methods: {

	},
};
</script>

<style lang="scss" scoped>
.bluetitle {
	display: flex;
	height:68px;
	justify-content: flex-start;
	align-items: center;
	.blue {
		margin-left:20px;
		width: 4px;
		height: 26px;
		background: #00A3E4;
	}

	.title {
		margin-left:10px;
		height: 28px;
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 28px;
	}

}
</style>